import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Input, Popover } from "antd";
import {
    LeftOutlined,
    PlayCircleOutlined,
    PauseCircleOutlined,
} from "@ant-design/icons";

import { compareParagraph } from "@Helper";
import "./index.scss";
var mySynth = window.speechSynthesis;
var utter = null;
const TestLesson = (props) => {
    const { history } = props;
    const { id } = props.match.params;
    const location = useLocation();
    const { state } = location;
    const lessonDetailReducer = useSelector((state) => {
        return state.lessonDetailReducer;
    });
    const [audio, setAudio] = useState(
        new Audio("http://techslides.com/demos/samples/sample.mp3")
    );
    const [play, setPlay] = useState(false);
    const [resume, setResume] = useState(false);
    const [userResult, setUserResult] = useState("");
    const [checkAnswer, setCheckAnswer] = useState("");
    audio.onended = () => {
        setPlay(false);
    };
    useEffect(() => {
        utter = new SpeechSynthesisUtterance(
            lessonDetailReducer.data[state.activeWord].practice
        );
        var test = mySynth.getVoices();
        for (let i = 0; i < test.length; i++) {
            if (test[i].lang === "en-US") {
                utter.voice = test[i];
                break;
            }
        }
    }, []);
    const _playAudio = () => {
        if (!play && !resume) {
            mySynth.speak(utter);
            setPlay(true);
        }
        if (play && !resume) {
            setResume(true);
            setPlay(false);
            mySynth.pause(utter);
        }
        if (!play && resume) {
            setPlay(true);
            setResume(false);
            mySynth.resume(utter);
        }
        utter.onend = () => {
            setPlay(false);
            setResume(false);
        };
    };
    const _goLearnLesson = () => {
        if (state.activeWord + 1 < lessonDetailReducer.data.length) {
            history.push({
                pathname: `/app-learn/lesson-detail/${id}/learn-lesson`,
                state: { activeWord: state.activeWord + 1 },
            });
        }
    };
    const _onChangeResult = (e) => {
        setUserResult(e.target.value);
    };
    const _checkAnswer = () => {
        let result = compareParagraph(
            userResult,
            lessonDetailReducer.data[state.activeWord].practice
        );
        setCheckAnswer(result);
    };
    return (
        <div className="test-lesson-container app-learn-container">
            <div className="header">
                <Button
                    onClick={() => history.goBack()}
                    type="text"
                    shape="circle"
                    icon={<LeftOutlined style={{ color: "white" }} />}
                />
                <div className="text">Tập nghe câu với từ sau</div>
            </div>
            <div className="body">
                <div style={{ marginBottom: "10px", fontSize: "30px" }}>
                    {lessonDetailReducer &&
                        lessonDetailReducer.data[state.activeWord].word}
                </div>
                <div>Nghe và viết lại câu bằng tiếng Đức</div>
                <Button
                    style={{ alignSelf: "center" }}
                    onClick={_playAudio}
                    type="text"
                    shape="circle"
                    icon={
                        play ? (
                            <PauseCircleOutlined style={{ fontSize: "25px" }} />
                        ) : (
                            <PlayCircleOutlined style={{ fontSize: "25px" }} />
                        )
                    }
                />
                <Input.TextArea
                    onChange={_onChangeResult}
                    style={{ margin: "10px 0" }}
                    placeholder="Hãy nhập đáp án của bạn"
                    autoSize={{ minRows: 2, maxRows: 3 }}
                />
                <Button
                    disabled={userResult === ""}
                    type="text"
                    className={`${userResult !== "" && "btn-test"}`}
                    onClick={_checkAnswer}
                >
                    Kiểm tra
                </Button>
                <div style={{ textAlign: "start", marginTop: "10px" }}>
                    {checkAnswer != "" && "Kết quả"}
                </div>
                <div
                    style={{ textAlign: "start" }}
                    dangerouslySetInnerHTML={{ __html: checkAnswer }}
                ></div>
            </div>
            <div className="footer">
                <Button
                    type={
                        lessonDetailReducer.data.length - 1 !==
                            state.activeWord && "text"
                    }
                    disabled={
                        lessonDetailReducer.data.length - 1 === state.activeWord
                    }
                    className={
                        lessonDetailReducer.data.length - 1 !==
                            state.activeWord && "btn-next"
                    }
                    onClick={_goLearnLesson}
                    style={{ width: "100%", margin: "20px 0" }}
                >
                    Tiếp theo
                </Button>
            </div>
        </div>
    );
};

export default TestLesson;
