import axios from "axios";
import Config from "@Config";

const api = axios.create({
    baseURL: Config.BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

export default api;
