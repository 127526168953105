import React from "react";
import { useLocation } from "react-router-dom";

import "./index.scss";

const Lesson = (props) => {
    const location = useLocation();
    const { lesson, indexLesson, history } = props;
    const _goLessonDetail = () =>{
        history.push(`/app-learn/lesson-detail/${lesson.id}`)
    }
    return (
        <div
            onClick={_goLessonDetail}
            className={`lesson ${
                lesson.learnedWords / lesson.totalWords === 1 ? "done" : "doing"
            }`}
        >
            <div>
                Bài học số {indexLesson + 1}: {lesson.name}
            </div>
            <div>
                {lesson.learnedWords}/{lesson.totalWords} từ
            </div>
        </div>
    );
};

export default Lesson;
