export const compareParagraph = (input, answer) => {
    input = input.replace(/\s\s+/g, " ");
    let inputSplit = input.split(" ");
    let answerSplit = answer.split(" ");

    let result = [];
    // xoá hết các "" cuối mảng
    let convertSplit = [...inputSplit];
    for (let i = inputSplit.length - 1; i >= 0; i--) {
        if (inputSplit[i] !== "") {
            break;
        } else {
            convertSplit.splice(i, 1);
        }
    }

    inputSplit = convertSplit;

    // case input > answer
    if (inputSplit.length >= answerSplit.length) {
        let answerChange = [];
        let indexOfInputSplit = 0;
        let count = 0;
        if (inputSplit.length > answerSplit.length) {
            for (let i = 0; i < answerSplit.length; i++) {
                count = 0;
                for (let j = indexOfInputSplit; j < inputSplit.length; j++) {
                    if (
                        answerSplit[i].toLowerCase() !==
                        inputSplit[j].toLowerCase()
                    ) {
                        count++;
                    }
                    if (
                        answerSplit[i].toLowerCase() ===
                        inputSplit[j].toLowerCase()
                    ) {
                        while (count !== 0) {
                            answerChange.push("");
                            count -= 1;
                        }
                        answerChange.push(answerSplit[i]);
                        indexOfInputSplit = j + 1;

                        break;
                    }
                    if (
                        answerSplit[i].toLowerCase() !==
                            inputSplit[j].toLowerCase() &&
                        j === inputSplit.length - 1
                    ) {
                        indexOfInputSplit = indexOfInputSplit + 1;
                        answerChange.push(answerSplit[i]);
                    }
                }
            }
            let checkLength = inputSplit.length - answerChange.length;
            while (checkLength !== 0) {
                answerChange.push("");
                checkLength -= 1;
            }
            answerSplit = answerChange;
        }
        for (let i = 0; i < inputSplit.length; i++) {
            if (answerSplit[i] === "") {
                result.push(
                    `<b style="color: red; text-decoration: line-through">${inputSplit[i]}</b>`
                );
            } else {
                if (
                    answerSplit[i].toLowerCase() === inputSplit[i].toLowerCase()
                ) {
                    result.push(`<b style="color: green">${inputSplit[i]}</b>`);
                } else {
                    result.push(`<b style="color: red">${answerSplit[i]}</b>`);
                }
            }
        }
    }

    // case input < answer
    if (inputSplit.length < answerSplit.length) {
        let indexOfInputSplit = 0;
        for (let i = 0; i < answerSplit.length; i++) {
            if (indexOfInputSplit < inputSplit.length) {
                for (let j = indexOfInputSplit; j < inputSplit.length; j++) {
                    if (
                        answerSplit[i].toLowerCase() ===
                        inputSplit[j].toLowerCase()
                    ) {
                        result.push(
                            `<b style="color: green">${inputSplit[j]}</b>`
                        );
                        indexOfInputSplit = j + 1;
                        break;
                    }
                    console.log(
                        answerSplit[i].toLowerCase() !==
                            inputSplit[j].toLowerCase() &&
                            j === inputSplit.length - 1
                    );
                    if (
                        answerSplit[i].toLowerCase() !==
                            inputSplit[j].toLowerCase() &&
                        j === inputSplit.length - 1
                    ) {
                        result.push(
                            `<b style="color: red">${answerSplit[i]}</b>`
                        );
                    }
                }
            } else {
                result.push(`<b style="color: red">${answerSplit[i]}</b>`);
            }
        }
    }

    return result.join(" ");
};
