import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "antd";
import {
    LeftOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    PlayCircleOutlined,
    AudioOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import ReactCardFlip from "react-card-flip";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./index.scss";

const LearnLesson = (props) => {
    const { history } = props;
    const { id } = props.match.params;
    const location = useLocation();
    const { listWords } = location.state;
    const [activeWord, setActiveWord] = useState(location.state.activeWord);
    const [isFlipped, setIsFlipped] = useState(false);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const lessonDetailReducer = useSelector((state) => {
        return state.lessonDetailReducer;
    });
    const _renderListExamples = () => {
        return lessonDetailReducer.data[activeWord].examples.map(
            (item, index) => {
                let word = lessonDetailReducer.data[
                    activeWord
                ].word.toLowerCase();
                let newParagraph = "";
                let splitParagraph = item.paragraph.split(" ");
                for (let i = 0; i < splitParagraph.length; i++) {
                    if (splitParagraph[i].toLowerCase().includes(word)) {
                        splitParagraph[i] = `<b>${splitParagraph[i]}</b>`;
                    }
                }
                newParagraph = splitParagraph.join(" ");
                return (
                    <div
                        style={{
                            marginBottom: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: newParagraph,
                                }}
                            ></div>
                            <div>{item.meanParagraph}</div>
                        </div>
                        <Button
                            onClick={() => setIsFlipped(true)}
                            type="text"
                            shape="circle"
                            icon={
                                <PlayCircleOutlined
                                    style={{ fontSize: "25px" }}
                                />
                            }
                        />
                    </div>
                );
            }
        );
    };
    const _goPraticeWord = () => {
        history.push({
            pathname: `/app-learn/lesson-detail/${id}/practice`,
            state: {
                activeWord,
            },
        });
    };
    return (
        <div className="app-learn-container global-padding">
            <ReactCardFlip isFlipped={isFlipped}>
                <div className="front-card">
                    <div className="header">
                        <Button
                            onClick={() => history.goBack()}
                            type="text"
                            shape="circle"
                            icon={<LeftOutlined />}
                        />
                        <div
                            style={{
                                fontSize: 20,
                                position: "absolute",
                                right: "50%",
                                transform: "translateX(50%)",
                                textAlign: "center",
                            }}
                        >
                            <Button
                                onClick={()=>setIsFlipped(true)}
                                type="text"
                                shape="circle"
                                icon={<RotateRightOutlined />}
                            />
                            <div style={{ fontSize: "10px" }}>
                                Chạm để xem nghĩa
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="word-content">
                            <div
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "30px",
                                    marginBottom: "10px",
                                }}
                            >
                                {lessonDetailReducer.data[activeWord].word}
                            </div>
                            <div style={{}}>Bấm để nghe âm chuẩn</div>
                            <div style={{ marginBottom: "30px" }}>
                                <Button
                                    type="text"
                                    shape="circle"
                                    icon={
                                        <PlayCircleOutlined
                                            style={{ fontSize: "40px" }}
                                        />
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    width: "50%",
                                    margin: "0 auto",
                                    border: "1px solid",
                                }}
                            ></div>
                        </div>
                        <div className="pronunciation">
                            <div className="action">
                                <div>Bấm để ghi âm</div>
                                <Button
                                    onClick={() => setIsFlipped(true)}
                                    type="text"
                                    shape="circle"
                                    icon={
                                        <AudioOutlined
                                            style={{ fontSize: "40px" }}
                                        />
                                    }
                                />
                            </div>
                            <div className="action">
                                <div>Bấm để nghe lại</div>
                                <Button
                                    onClick={() => setIsFlipped(true)}
                                    type="text"
                                    shape="circle"
                                    icon={
                                        <PlayCircleOutlined
                                            style={{ fontSize: "40px" }}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <Button
                        type="text"
                        onClick={_goPraticeWord}
                        className="btn-practice"
                    >
                        Làm bài tập
                    </Button>
                </div>

                <div className="back-card">
                    <div className="header">
                        <div
                            style={{
                                fontSize: 20,
                                flex: 1,
                                textAlign: "center",
                            }}
                        >
                            <Button
                                onClick={() => setIsFlipped(false)}
                                type="text"
                                shape="circle"
                                icon={<RotateLeftOutlined />}
                            />
                        </div>
                    </div>
                    <div className="mean">
                        <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                            Nghĩa
                        </div>
                        <div>
                            {lessonDetailReducer.data[activeWord].mean}
                            <div
                                style={{
                                    borderBottom: "1px solid",
                                    marginTop: "20px",
                                }}
                            ></div>
                        </div>
                    </div>
                    <div className="example-list">
                        <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                            Ví dụ
                        </div>
                        <div>{_renderListExamples()}</div>
                    </div>
                </div>
            </ReactCardFlip>
        </div>
    );
};

export default LearnLesson;
