import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { saveLesson } from "@Redux/Reducers/LessonDetailRedux";
import "./index.scss";

// du lieu mau chua co api
const data = [
    {
        idWord: "word 1",
        word: "Hello",
        mean: "Xin chào",
        yourPronunciation: "audio.mp3, ...",
        examples: [
            {
                paragraph: "Hello my friend",
                meanParagraph: "Xin chào bạn của tôi",
            },
        ],
        learn: false,
    },
    {
        idWord: "word 1",
        word: "Home",
        mean: "Ngôi nhà",
        yourPronunciation: "audio.mp3, ...",
        examples: [
            {
                paragraph: "Home is very beautiful",
                meanParagraph: "Ngôi nhà của tôi rất đẹp",
            },
            {
                paragraph:
                    "You have nice home. Home is very great. Home is the best",
                meanParagraph: "Bạn có ngôi nhà thật tuyệt",
            },
        ],
        learn: false,
        practice: "Home is the best",
    },
];

const LessonDetail = (props) => {
    const dispatch = useDispatch();
    const lessonDetailReducer = useSelector(
        (state) => state.lessonDetailReducer
    );
    const { history, match } = props;
    const { id } = match.params;
    const _getLessonDetail = async () => {};
    const _goLearnLesson = (activeWord = 0) => {
        history.push({
            pathname: `/app-learn/lesson-detail/${id}/learn-lesson`,
            state: { activeWord: activeWord, listWords: data },
        });
    };
    useEffect(() => {
        if (id !== lessonDetailReducer.id) {
            dispatch(saveLesson({ data, id }));
        }
    }, []);
    const _renderListWord = () => {
        return data.map((item, index) => {
            return (
                <div onClick={() => _goLearnLesson(index)} className="word">
                    {item.word}
                </div>
            );
        });
    };
    return (
        <div className="lesson-detail-container app-learn-container global-padding">
            <div className="header">
                <Button
                    onClick={() => history.goBack()}
                    type="text"
                    shape="circle"
                    icon={<LeftOutlined />}
                />
                <div style={{ fontSize: 20, flex: 1, textAlign: "center" }}>
                    Danh sách từ
                </div>
            </div>
            <div className="list-words">{_renderListWord()}</div>
            <div className="action">
                <Button type="primary" style={{ width: "48%", color: "white" }}>
                    Thực hành
                </Button>
                <Button
                    onClick={() => _goLearnLesson()}
                    style={{
                        width: "48%",
                        backgroundColor: "#66cc02",
                        color: "white",
                    }}
                >
                    Học từ
                </Button>
            </div>
        </div>
    );
};

export default LessonDetail;
