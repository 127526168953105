import React, { useState, useEffect, useMemo, useRef } from "react";
import { Button, Container, Stepper } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";

import api from "../../../../Api";

import Question from "../Question";
import ExamStepper from "../ExamStepper";
import TimeCountDown from "../TimeCountDown";
import TabResult from "../TabResult";
import FormUser from "../FormUser";
import CountUpTime from "../CountUpTime";
import Config from "@Config";

let countUp = null;
let url = new URL(window.location.href);
let language_id = url.searchParams.get("language_id");

const Exam = (props) => {
    const [isCheckingAnswer, setIsCheckingAnswer] = useState(false);
    const [firstTimeTest, setFirstTimeTest] = useState(true);
    const [incorrectAnswer, setIncorrectAnswer] = useState(0);
    const firstRender = useRef(true);
    const [userResult, setUserResult] = useState(null);
    const [openFormUser, setOpenFormUser] = useState(false);
    const [correctOfQuestion, setCorrectOfQuestion] = useState(0);
    const [showTabResult, setShowTabResult] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [curLevel, setCurLevel] = useState(null);
    const [nextLevel, setNextLevel] = useState(null);
    const [prevLevel, setPrevLevel] = useState(null);
    const [timeOfLevel, setTimeOfLevel] = useState(null);
    const [activeQuestion, setActiveQuestion] = useState(0);
    const [questionOfExam, setQuestionOfExam] = useState([]);
    const [totalQuestionOfExam, setTotalQuestionOfExam] = useState([]);
    const [numGetQuestion, setNumGetQuestion] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);

    const getQuestion = async (level_id = null) => {
        setActiveQuestion(0);
        setCorrectOfQuestion(0);
        let res = await api.get(
            `/get_5_questions?language_id=${
                language_id ? language_id : ""
            }&level_id=${level_id ? level_id : ""}`
        );
        const {
            curr_level,
            next_level,
            prev_level,
            questions,
            time,
        } = res.data.data;
        if (res.status) {
            setCurLevel(curr_level);
            setNextLevel(next_level);
            setPrevLevel(prev_level);
            setTimeOfLevel(time);
            let newQuestionOfExam = questions.map((item, index) => {
                return {
                    index: index,
                    ...item,
                    userSelected: null,
                };
            });
            setQuestionOfExam(newQuestionOfExam);
            countUp = setInterval(() => {
                setSeconds((current) => current + 1);
            }, 1000);
            setNumGetQuestion((current) => current + 1);
            setShowTabResult(false);
        }
    };

    useEffect(() => {
        if (language_id) {
            getQuestion();
        }
    }, []);

    const renderQuestions = () => {
        return (
            <Question
                activeQuestion={activeQuestion}
                setActiveQuestion={setActiveQuestion}
                questionDetail={questionOfExam[activeQuestion]}
                questionOfExam={questionOfExam}
                indexQuestion={activeQuestion}
                onUserSelect={onUserSelect}
                checkUserLevel={checkUserLevel}
                isCheckingAnswer={isCheckingAnswer}
            />
        );
    };

    const ExamStepperMemo = useMemo(() => {
        return (
            <ExamStepper
                activeQuestion={activeQuestion}
                setActiveQuestion={setActiveQuestion}
                questionOfExam={questionOfExam}
            />
        );
    }, [questionOfExam, activeQuestion]);

    const onUserSelect = async (indexQuestion, indexAnswer, answerId) => {
        let newQuestionOfExam = [...questionOfExam];
        for (let i = 0; i < questionOfExam.length; i++) {
            if (newQuestionOfExam[i].index === indexQuestion) {
                newQuestionOfExam[i].userSelected = indexAnswer;
                newQuestionOfExam[i].answerId = answerId;

                // tao form dap an call api check dap an
                let form = [
                    {
                        question_id: newQuestionOfExam[i].id,
                        answer_id: answerId,
                    },
                ];
                setIsCheckingAnswer(true);
                const res = await api.post(`check_answer`, form);
                setIsCheckingAnswer(false);
                if (res.status) {
                    newQuestionOfExam[i].check =
                        res.data.data && res.data.data[0].correct_answer_id;
                    setCorrectOfQuestion(
                        (current) => current + res.data.numb_correct_question
                    );
                    if (res.data.numb_correct_question_correct_question === 0) {
                        setIncorrectAnswer((current) => current + 1);
                    } else {
                        setIncorrectAnswer(0);
                    }
                }

                break;
            }
        }
        setQuestionOfExam(newQuestionOfExam);
    };

    // lay 5 cau hoi tiep theo theo trinh do
    const checkUserLevel = () => {
        clearInterval(countUp);
        if (nextLevel.id !== "") {
            setTotalQuestionOfExam([...totalQuestionOfExam, ...questionOfExam]);
            if (correctOfQuestion >= 18) {
                getQuestion(nextLevel.id);
                setFirstTimeTest(false);
            } else {
                if (15 <= correctOfQuestion && correctOfQuestion <= 17) {
                    checkUserLevelByTime(curLevel);
                } else {
                    if (correctOfQuestion < 10 && firstTimeTest) {
                        setUserResult(null);
                        setOpenFormUser(true);
                    } else {
                        if (!prevLevel?.id) {
                            setUserResult(curLevel);
                            setOpenFormUser(true);
                        } else {
                            setUserResult(prevLevel);
                            setOpenFormUser(true);
                        }
                    }
                }
            }
        } else {
            if (!nextLevel?.id) {
                setTotalQuestionOfExam([
                    ...totalQuestionOfExam,
                    ...questionOfExam,
                ]);
                if (15 <= correctOfQuestion) {
                    checkUserLevelByTime(curLevel);
                } else {
                    if (correctOfQuestion < 10 && firstTimeTest) {
                        setUserResult(null);
                        setOpenFormUser(true);
                    } else {
                        if (!prevLevel?.id) {
                            setUserResult(curLevel);
                            setOpenFormUser(true);
                        } else {
                            setUserResult(prevLevel);
                            setOpenFormUser(true);
                        }
                    }
                }
            }
        }
    };

    const checkUserLevelByTime = (level) => {
        if (timeOfLevel !== 0) {
            if (!nextLevel?.id) {
                if (minutes < timeOfLevel + 20) {
                    setUserResult(level);
                    setOpenFormUser(true);
                } else {
                    setUserResult(prevLevel);
                    setOpenFormUser(true);
                }
            } else {
                if (minutes < timeOfLevel + 10) {
                    setUserResult(level);
                    setOpenFormUser(true);
                } else {
                    setUserResult(prevLevel);
                    setOpenFormUser(true);
                }
            }
        } else {
            setUserResult(curLevel);
            setOpenFormUser(true);
        }
    };

    // check neu nguoi dung chon 6 cau sai lien tiep
    useEffect(() => {
        if (incorrectAnswer === 6) {
            clearInterval(countUp);
            checkUserLevel();
        }
    }, [incorrectAnswer]);

    // useEffect(() => {
    //     console.log("useeffect: ", numGetQuestion)
    //     if (numGetQuestion === 2) {
    //         setOpenFormUser(true);
    //         // setShowTabResult(true);
    //     }
    // }, [totalQuestionOfExam]);

    // check level nguoi dung theo thoi gian lam bai
    useEffect(() => {
        if (!firstRender.current) {
            setOpenFormUser(true);
        }
        firstRender.current = false;
    }, [userResult]);

    // xu ly count up time
    // useEffect(() => {
    //     countUp = setInterval(() => {
    //         setSeconds((current) => current + 1);
    //     }, 1000);
    // }, []);

    useEffect(() => {
        if (seconds === 60) {
            setSeconds(0);
            setMinutes((current) => current + 1);
        }
    }, [seconds]);

    return (
        <Container maxWidth="md">
            {language_id ? (
                <div>
                    {/* {TimeCountDownMemo} */}
                    
                    <CountUpTime
                        minutes={minutes}
                        setMinutes={setMinutes}
                        seconds={seconds}
                        setSeconds={setSeconds}
                        countUp={countUp}
                    />
                    {/* {ExamStepperMemo} */}
                    <div className="question-component">
                        {questionOfExam.length !== 0 && renderQuestions()}
                    </div>
                    <Snackbar
                        open={openSnackBar}
                        message="Bạn chưa trả lời hết các câu hỏi"
                        onClose={() => setOpenSnackBar(false)}
                    />
                    <FormUser
                        userResult={userResult}
                        language_id={language_id}
                        setShowTabResult={setShowTabResult}
                        openFormUser={openFormUser}
                        setOpenFormUser={setOpenFormUser}
                        minutes={minutes}
                        seconds={seconds}
                        showTabResult={showTabResult}
                    />
                </div>
            ) : (
                <div>Chưa chọn ngôn ngữ</div>
            )}
        </Container>
    );
};

export default Exam;
