import React, { useState } from "react";
import { Button } from "@material-ui/core";

import "./index.scss";

const Question = (p) => {
    const {
        questionDetail,
        activeQuestion,
        setActiveQuestion,
        onUserSelect,
        questionOfExam,
        tabResult,
        checkUserLevel,
    } = p;

    const [currentAudio, setCurrentAudio] = useState(null);

    const onNextActiveQuestion = () => {
        let audio = document.getElementById("audio-cache");
        if (audio) {
            audio.pause();
            audio.currentTime = 0;
        }
        if (activeQuestion < questionOfExam.length - 1) {
            setActiveQuestion((current) => current + 1);
        } else {
            checkUserLevel();
        }
    };

    const renderAnswers = () => {
        return questionDetail.answers.map((item, index) => {
            if (questionOfExam[activeQuestion].userSelected === index) {
                return (
                    <Button
                        key={`${activeQuestion}-${index}`}
                        disabled={questionOfExam[activeQuestion].check && true}
                        className={`answer answer-select`}
                        onClick={() => onUserSelect(activeQuestion, index, item.id)}
                    >
                        {index + 1}. {item.answer}
                    </Button>
                );
            } else {
                return (
                    <Button
                        key={`${activeQuestion}-${index}`}
                        disabled={
                            // questionOfExam[activeQuestion].userSelected !== null
                            questionOfExam[activeQuestion].check
                        }
                        onClick={() => onUserSelect(activeQuestion, index, item.id)}
                        className={`answer`}
                    >
                        {index + 1}. {item.answer}
                    </Button>
                );
            }
        });
    };

    const renderAnswersOfTabResult = () => {
        return questionDetail.answers.map((item, index) => {
            if (
                questionOfExam[activeQuestion].userSelected === index ||
                questionOfExam[activeQuestion].check === item.id
            ) {
                return (
                    <Button
                        disabled
                        className={`answer ${
                            item.id === questionOfExam[activeQuestion].check ? "correct-answer" : "incorrect-answer"
                        }`}
                    >
                        {index + 1}. {item.answer}
                    </Button>
                );
            } else {
                return (
                    <Button disabled className={`answer`}>
                        {index + 1}. {item.answer}
                    </Button>
                );
            }
        });
    };
    const renderNewAudio = () => {
        let audio = document.getElementById("audio-cache");
        if (currentAudio !== questionDetail.audio) {
            setCurrentAudio(questionDetail.audio);
            if (audio) {
                audio.load();
            }
        }
    };
    return (
        <div className="question-container">
            {tabResult === true ? (
                <>
                    {" "}
                    <div className="">
                        Câu hỏi {activeQuestion + 1}: {questionDetail.question}
                    </div>
                    <div className="answer-part">{questionOfExam && renderAnswersOfTabResult()}</div>
                </>
            ) : (
                <>
                    {" "}
                    <div
                        style={{
                            paddingBottom: "20px",
                            marginBottom: "10px",
                            borderBottom: "3px solid #c3c3c3",
                        }}
                        className="audio-content-container"
                    >
                        <div className="audio">
                            {/* {console.log("cccccccc: ", questionDetail.audio)} */}
                            {questionDetail.audio && (
                                <audio
                                    id="audio-cache"
                                    style={{
                                        outline: "none",
                                        width: "100%",
                                        marginBottom: "10px",
                                    }}
                                    controls
                                    controlsList="nodownload"
                                >
                                    <source id="audio-source" src={questionDetail.audio} />
                                </audio>
                            )}
                            {renderNewAudio()}
                        </div>
                        <div className="content">{questionDetail.content && questionDetail.content}</div>
                    </div>
                    <div className="">
                        Câu {activeQuestion + 1}: {questionDetail.question}
                    </div>
                    <div className="answer-part">{questionOfExam && renderAnswers()}</div>
                    <div className="back-next-button-container">
                        <Button
                            disabled={questionOfExam[activeQuestion].check ? false : true}
                            onClick={onNextActiveQuestion}
                            variant="contained"
                        >
                            Tiếp theo
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Question;
