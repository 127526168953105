import React from "react";
import { MobileStepper, Button, Paper } from "@material-ui/core";

import "./index.scss";

const ExamStepper = (p) => {
    const { questionOfExam, activeQuestion, setActiveQuestion } = p;

    const onChangeActiveQuestion = (index) => {
        setActiveQuestion(index);
    };
    const renderQuestionCount = () => {
        let html = null;
        if (questionOfExam.length !== 0) {
            html = questionOfExam.map((item, index) => {
                if (
                    questionOfExam &&
                    questionOfExam[index].userSelected !== null
                ) {
                    return (
                        <Paper
                            key={index}
                            onClick={()=>onChangeActiveQuestion(index)}
                            elevation={0}
                            className={`question-count  ${
                                index === activeQuestion
                                    ? "active-question"
                                    : ""
                            }`}
                        >
                            {index + 1}
                        </Paper>
                    );
                } else {
                    return (
                        <Paper
                            onClick={()=>onChangeActiveQuestion(index)}
                            elevation={0}
                            className={`question-count ${
                                index === activeQuestion
                                    ? "active-question"
                                    : ""
                            }`}
                        >
                            {index + 1}
                        </Paper>
                    );
                }
            });
        }
        return html;
    };
    return (
        <div className="exam-stepper-container">{renderQuestionCount()}</div>
    );
};

export default ExamStepper;
