import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import indexRouters from "@Routers";
import "@Common/Style/globalStyle.scss";
import "./App.css";

function App() {
    return (
        <Router>
            <div className="App">
                <Switch>
                    {indexRouters.map((item, index) => (
                        <Route key={index} path={item.path} exact={item.exact} component={item.component} />
                    ))}
                </Switch>
            </div>
        </Router>
    );
}

export default App;
