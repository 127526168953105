import React, { useState } from "react";
import { Form, Input, Button, Modal } from "antd";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CheckCircleOutlined } from "@ant-design/icons";

import api from "../../../../Api";

import "./index.scss";
import { CheckOutlined } from "@material-ui/icons";

const FormUser = (props) => {
  const {
    openFormUser,
    setOpenFormUser,
    setShowTabResult,
    userResult,
    showTabResult,
    language_id,
    minutes,
    seconds,
  } = props;
  const [hideButton, setHideButton] = useState(false);
  const [values, setValues] = useState({});

  const handleChangeValue = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setValues({ ...values, [name]: value });
  };
  const handleSubmit = async (values) => {
    setHideButton(true);
    const res = await api.post(`contact`, {
      ...values,
      language_id,
      level_id: userResult && userResult.id,
      time: minutes * 60 + seconds,
    });
    if (res.data.status) {
      // setOpenFormUser(false);
      setShowTabResult(true);
    }
  };
  return (
    <Modal
      // onCancel={() => setOpenFormUser(false)}
      footer={null}
      visible={openFormUser}
    >
      <div
        style={{ textAlign: "center", marginBottom: "30px", marginTop: "30px" }}
      >
        <CheckCircleOutlined style={{ color: "green", fontSize: 30 }} />
        {!showTabResult ? (
          <>
            <div style={{ margin: "10px 0" }}>
              Tuyệt vời! Bạn đã hoàn thành bài kiểm tra trình độ.
            </div>

            <div>
              Bạn hãy điền thông tin email nếu muốn nhận thông tin tư vấn và kết
              quả bài kiểm tra! 😉
            </div>
          </>
        ) : (
          <>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              Kết quả đã được gửi đến email của bạn. Vui lòng kiểm tra hộp thư
              nhé.
            </div>
          </>
        )}
      </div>
      {!showTabResult && (
        <Form onFinish={handleSubmit}>
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Hãy nhập họ tên!" }]}
          >
            <Input placeholder="Họ tên" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Hãy nhập email!" }]}
          >
            <Input placeholder="Email" type="email" />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: "Hãy nhập số điện thoại!",
              },
            ]}
          >
            <Input placeholder="Số điện thoại" type="number" />
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            {!hideButton && (
              <Button type="primary" htmlType="submit">
                Gửi thông tin
              </Button>
            )}
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default FormUser;
