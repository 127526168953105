import React from "react";
import { Progress, Button } from "antd";
import Lesson from "../Lesson";

import "./index.scss";

const dataLessons = [
    {
        id: "lesson1",
        name: "School",
        totalWords: 10,
        learnedWords: 6,
    },
    {
        id: "lesson2",
        name: "Home",
        totalWords: 10,
        learnedWords: 10,
    },
    {
        id: "lesson3",
        name: "Sport",
        totalWords: 10,
        learnedWords: 2,
    },
];

const MainAppLearn = (props) => {
    const {history} = props
    const _renderLessons = () => {
        return dataLessons.map((lesson, index) => (
            <Lesson history={history} lesson={lesson} indexLesson={index} />
        ));
    };
    return (
        <div className="app-learn-container global-padding">
            <div>
            <div style={{ textAlign: "center" }}>Easy German</div>
            <Progress percent={60} />
            </div>
            <div className="list-lessons-container">{_renderLessons()}</div>
            <Button type="primary" style={{width: "100%", margin: "20px 0px"}}>Ôn tập</Button>
        </div>
    );
};

export default MainAppLearn;
