import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const lessonSlice = createSlice({
    name: "lesson",
    initialState,
    reducers: {
        saveLesson(state, action) {
            console.log("999: ", action.payload)
            state.data = action.payload.data;
            state.id = action.payload.id
        },
    },
});

export const { saveLesson } = lessonSlice.actions;

export default lessonSlice.reducer;
