import AppTestLevel from "@Containers/AppTestLevel/Exam";
import AppLearn from "@Containers/AppLearn/MainAppLearn";
import LessonDetail from "@Containers/AppLearn/LessonDetail";
import LearnLesson from "@Containers/AppLearn/LearnLesson";
import TestLesson from "@Containers/AppLearn/TestLesson";

export default [
    {
        path: "/app-learn/lesson-detail/:id/practice",
        exact: true,
        component: TestLesson,
    },
    {
        path: "/app-learn/lesson-detail/:id/learn-lesson",
        exact: true,
        component: LearnLesson,
    },
    {
        path: "/app-learn/lesson-detail/:id",
        exact: true,
        component: LessonDetail,
    },
    {
        path: "/app-learn",
        exact: true,
        component: AppLearn,
    },
    {
        path: "/",
        exact: true,
        component: AppTestLevel,
    },
];
