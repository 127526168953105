import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";

import "./index.scss";

const CountUpTime = (props) => {
    const { seconds, setSeconds, minutes, setMinutes, countUp } = props;

    return (
        <div>
            <div style={{textAlign: "center", fontSize: 15, color: "red"}}>Tổng thời gian làm bài có ảnh hưởng đến kết quả bài kiểm tra. Bạn đừng làm quá lâu nhé!</div>
        
        <div
            style={{
                textAlign: "center",
                padding: "5px 20px 20px 20px",
                display: "flex",
                justifyContent: "center",
            }}
        >
            
            <div
                style={{
                    backgroundColor: "#3a2325",
                    color: "#f1f0f0",
                    marginRight: "2px",
                    fontSize: "25px",
                    borderRadius: "10px",
                    padding: "0 10px",
                }}
            >
                {minutes < 10 ? `0${minutes}` : minutes}
            </div>
            <div
                style={{
                    backgroundColor: "#3a2325",
                    color: "#f1f0f0",
                    marginLeft: "2px",
                    fontSize: "25px",
                    borderRadius: "10px",
                    padding: "0 10px",
                }}
            >
                {seconds < 10 ? `0${seconds}` : seconds}
            </div>
        </div>
        </div>
    );
};

export default CountUpTime;
