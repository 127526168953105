import Config from '@Config'
import * as Sentry from "@sentry/browser";

let initiated = false;
const sentry = {
    init: () => {
        if (!initiated && Config.NODE_ENV === "production") {
            Sentry.init({
                dsn: "https://63cf4939c38d45e0b05e3ca496f3e85a@sentry.phuongnamdigital.com/27",
                tracesSampleRate: 1.0,
                tunnel: "https://sentry.phuongnamdigital.com/tunnel.php",
                beforeSend: (event, hint) => {
                    const values = event?.exception?.values || [];
                    const messages = [];
                    values.forEach((value) => {
                        if (typeof value.value === "string") {
                            messages.push(value.value);
                        }
                    });
                    if (typeof event.message === "string") {
                        messages.push(event.message);
                    }
                    if (typeof hint.originalException === "string") {
                        messages.push(hint.originalException);
                    } else {
                        messages.push(hint.originalException?.message || "");
                    }
                    const ignoreMessages = [
                        /Request failed with status code/,
                        /Network Error/,
                        /unmounted component/,
                    ];
                    for (let message of messages) {
                        if (ignoreMessages.find((regex) => message.match(regex))) {
                            return null;
                        }
                    }
                    return event;
                },
            });
            initiated = true;
        }
    },
};

export default sentry;
